<template>
  <div></div>
</template>

<script>
import { showAppNotification } from '@/utils/appNotification';
export default {
  name: "Notifications",
  data() {
    return {
      status: "",
      message: "",
      notificationType: this.$route.query.notificationType,
    };
  },
  methods: {
    createNotification() {
      if ( this.notificationType === "depositPbt" ) this.depositPbtNotification()
      if ( this.message ) showAppNotification( this.status, this.message )
    },
    depositPbtNotification() {
      const reply = this.$route.query.Reply
      this.message = "Deposit creation error"
      this.status = "error"
      switch (reply) {
        case "G500":
          this.message = "Deposit cancelled by the cardholder"
          break

        case "G340":
          this.message = "Wrong CVV"
          break
        
        case "G520":
          this.message = "Incorrect card expiration date"
          break

        case "000":
          this.message = "Deposit successfully created"
          this.status = "success"
          break
      }
    }
  },
  created() {
    this.createNotification()
    this.$router.push( { name: "account" } )
  },
};
</script>